import React from "react"
import { Layout, FlexCol, Parallax } from "@components/layout"
import { Breadcrumbs, ResizedImage as Image } from "@components/shared"

import { GoogleCalendar } from "@components/standard"

const ArrangeMeeting = ({ pageContext }) => {
  return (
    // <Layout
    //     animation={false}

    // >
    //     <Breadcrumbs
    //         elements={[
    //             {
    //                 label: page.title,
    //                 url: `/${page.slug}/`,
    //             },
    //         ]}
    //     />
    <div>
      <GoogleCalendar />
    </div>
    // </Layout>
  )
}

export default ArrangeMeeting
